<template>
  <div class="main"
       v-loading="Loading"
       element-loading-text="拼命加载中"
       element-loading-spinner="el-icon-loading"
       element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="head">
      <hr>
    </div>
    <div class="userinfo">
      <div class="user">
        <div class="username"
             style="display:inline-block;float:right;">
          <span>当前角色：</span><span>{{ info.charac_name }}</span><a class="logout"
             href="javascript:;"
             @click="LoginDisplay = 'display:block'">《登录|切换》</a>
        </div>
        <div class="info"
             style="display:inline-block;">已累计签到<span>{{ info.config.dayNum }}</span>天</div>

        <div v-if="info.config.signState == 1"
             class="status">今日已签到，记得明天再来签到哦！<a class="btn"
             href="javascript:;"
             @click="getInfo">刷新</a></div>
        <div v-if="info.config.signState == 0"
             class="status">
          <span v-if="info.config.type == 1">
            今日再消耗{{ info.config.fatigue }}点疲劳，即可签到今日奖励。<a class="btn"
               href="javascript:;"
               @click="getInfo">刷新</a>
          </span>
          <span v-if="info.config.type == 2">
            今日再挑战[<span style="color:#f90000">{{ info.config.room_name }}</span>],{{ info.config.fatigue
                        }}次，即可签到今日奖励。<a class="btn"
               href="javascript:;"
               @click="getInfo">刷新</a>
          </span>
        </div>
      </div>
    </div>
    <div class="event-daily">
      <div class="title title-daily">日常签到奖励</div>
      <div class="daily-list">

        <div v-for="(v, i) in info.plug_sign_week"
             :key="i"
             class="daily-item"
             :class="v.current">
          <div class="daily-title">{{ v.week }}</div>
          <div class="icon"
               v-on:mouseover="lj_info($event, v.code)"
               v-on:mouseout="removeActive($event)">
            <img :src="v.ioc">
          </div>
        </div>
      </div>
      <div class="daily-btn">
        <span v-if="info.config.signState == 1">今日已领取</span>
        <a v-if="info.config.signState == 0"
           class="btn"
           id="c1"
           href="javascript:;"
           @click="sign()">签到</a>
      </div>
    </div>
    <div class="event-count">
      <div class="title title-count">累计签到奖励</div>
      <div class="count-list"
           style="overflow:auto;position:absolute;left: 0px;top:34px;width: 368px;">
        <el-carousel :interval="3000"
                     type="card"
                     height="92px">
          <el-carousel-item v-for="(v, i) in info.plug_sign_lj"
                            :key="i">
            <div class="count-item">
              <div class="count-title">{{ v.day }}天</div>
              <div class="lj"
                   v-on:mouseover="lj_info($event, v.code)"
                   v-on:mouseout="removeActive($event)">
                <img :src="v.ioc">
              </div>
              <span v-if="v.receive == 1">已领取</span>
              <span v-else-if="v.receive == 0 && v.day > info.config.dayNum">未达成</span>
              <a v-else-if="v.receive == 0 && v.day <= info.config.dayNum"
                 class="btn"
                 href="javascript:;"
                 @click="receive(v.day)">领取</a>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="tip">*累计奖励全部领取后，将重置累计签到奖励和重置签<br>到天数。</div>
    </div>
    <div class="iteminfo">
      <div style="text-align:center;">物品礼包</div>
      <div v-for="(itmec, i) in itme_show_data"
           :key="i"
           style="padding:0px 6px;">{{ itmec.name }}x{{ itmec.num }}
      </div>
    </div>
    <div class="alert">
      <!-- 登陆框 -->
      <div class="alert_bg"
           :style="LoginDisplay">
        <div class="alert_main"
             style="margin-top:86px;width:350px;">
          <div class="alert_title"><span>绑定|切换</span></div>
          <div class="alert_con">
            <div class="alert_com">
              <p style="margin-bottom:6px;display:flex;"><span style="padding:0px 0px 0px 32px;">游戏账号：</span><input type="text"
                       v-model="login_info.username"
                       class="service__input"
                       style="background:#000;border:1px solid #442e21;color:#abce21;width: 220px;"></p>
              <p style="margin-bottom:6px;display:flex;"><span style="padding:0px 0px 0px 32px;">游戏密码：</span><input type="password"
                       v-model="login_info.password"
                       class="service__input"
                       style="background:#000;border:1px solid #442e21;color:#abce21;width: 220px;"></p>
              <p style="margin-bottom:6px;display:flex;"><span style="padding:0px 0px 0px 32px;">绑定角色：</span>
                <select v-model="login_info.role"
                        style="width:173px;background:#000;border:1px solid #442e21;color:#abce21;">
                  <option v-for="(itmec, i) in role_data"
                          :key="i"
                          :value="itmec.charac_no">
                    {{ itmec.charac_name }}
                  </option>
                </select>
                <span style="background:#7478e4;padding:3px 10px;margin-left:2px;border-radius:4%;cursor:pointer;"
                      @click="getRole">获取</span>
              </p>
              <div>
                <span style="padding:0px 0px;color:#f90000;"><span style="display:inline-block;padding:0px 0px;">{{
                                                LoginError
                                        }}</span></span>
              </div>
              <div style="text-align:center;padding:10px 0px">
                <div class="btn"
                     @click="login"
                     style="margin:0px 0px;display:inline-block;cursor:pointer;">确定
                </div>
                <div class="btn"
                     @click="LoginDisplay = 'display:none'"
                     style="margin:0px 0px;display:inline-block;cursor:pointer;">取消</div>
              </div>
            </div>
          </div>
          <div class="alert_bottom"><span></span></div>
        </div>
      </div>
      <!-- 登陆框 end-->
      <!-- 弹出框 -->
      <div class="alert_bg"
           :style="MessageDisplay">
        <div class="alert_main">
          <div class="alert_title"><span>提示</span></div>
          <div class="alert_con">
            <div class="alert_com">
              <span>{{ MessageMsg }}</span>
              <div style="text-align:center;padding:10px 0px">
                <div class="btn"
                     @click="CloseAlert"
                     style="margin:0px 0px;display:inline-block;">
                  关闭</div>
              </div>
            </div>
          </div>
          <div class="alert_bottom"><span></span></div>
        </div>
      </div>
      <div class="alert_bg"
           :style="SuccessDisplay">
        <div class="alert_main">
          <div class="alert_title"><span>提示</span></div>
          <div class="alert_con">
            <div class="alert_com">
              <span>{{ MessageMsg }}</span>

              <div style="text-align:center;padding:10px 0px">
                <div class="btn"
                     @click="SuccessAlert"
                     style="margin:0px 0px;display:inline-block;">关闭
                </div>
              </div>
            </div>
          </div>
          <div class="alert_bottom"><span></span></div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Vue from 'vue'
export default {
  name: "Sign",
  data () {
    return {
      MessageDisplay: "display:none", //弹出框显示
      SuccessDisplay: 'display:none',//成功弹出框
      LoginDisplay: "display:none", //登陆弹出框
      LoginError: '',
      MessageMsg: '', //弹出框消息
      Loading: false,
      info: {
        m_id: 0, //账号uid
        charac_no: 0, //角色ID
        charac_name: '', //角色名称
        plug_sign_week: [],
        config: {
          weeks: 0,
          fatigueConsumption: 0,
          room_num: 0,
          dayNum: 0,
          signState: 0,
          type: 0,
          fatigue: 0,
          room_name: '',
        },
        plug_sign_lj: [],
      },
      login_info: {
        username: '',
        password: '',
        role: '',
      },
      itme_show_data: [], //展示物品用
      role_data: [],
    };
  },
  created () {
    this.getInfo()
  },
  methods: {
    //获取基本配置信息
    getInfo () {
      this.Loading = true;
      this.axios({
        // 默认请求方式为get
        method: "post",
        url: Vue.prototype.config.webplugurl + "/sign/getSignInfo",
        // 传递参数
        data: {
          key: this.$route.query.key,
          keyid:this.$route.query.id,
        },
        responseType: "json",
      })
        .then((response) => {
          this.Loading = false;
          if (response.data.code == 200) {
            this.info = response.data.info
          } else {
            this.PopMessage(true, response.data.msg);
          }
        })
        .catch((error) => {
          this.Loading = false;
          // 请求失败，
          console.log(error);
        });
    },
    //登陆
    login () {
      if (this.Loading == true) {
        return false;
      }
      this.Loading = true;
      this.axios({
        // 默认请求方式为get
        method: "post",
        url: Vue.prototype.config.webplugurl + "/PlugLogin",
        // 传递参数
        data: {
          key: this.$route.query.key,
          keyid:this.$route.query.id,
          username: this.login_info.username,
          password: this.login_info.password,
          role: this.login_info.role,
        },
        responseType: "json",
      })
        .then((response) => {
          this.Loading = false;
          if (response.data.code == 200) {
            this.LoginDisplay = "display:none";
            this.getInfo();
          } else {
            this.LoginError = response.data.msg;
          }
        })
        .catch((error) => {
          this.Loading = false;
          // 请求失败，
          console.log(error);
        });
    },
    //获取角色
    getRole () {
      if (this.Loading == true) {
        return false;
      }
      this.Loading = true;
      this.axios({
        // 默认请求方式为get
        method: "post",
        url: Vue.prototype.config.webplugurl + "/getRole",
        // 传递参数
        data: {
          key: this.$route.query.key,
          keyid:this.$route.query.id,
          username: this.login_info.username,
          password: this.login_info.password,
        },
        responseType: "json",
      })
        .then((response) => {
          this.Loading = false;
          if (response.data.code == 200) {
            this.role_data = response.data.info;
          } else {
            this.LoginError = response.data.msg;
          }
        })
        .catch((error) => {
          this.Loading = false;
          // 请求失败，
          console.log(error);
        });
    },
    sign () {
      if (this.Loading == true) {
        return false
      }
      this.Loading = true;
      this.axios({
        // 默认请求方式为get
        method: "post",
        url: Vue.prototype.config.webplugurl + "/sign/SignSubmit",
        // 传递参数
        data: {
          key: this.$route.query.key,
          keyid:this.$route.query.id,
        },
        responseType: "json",
      })
        .then((response) => {
          this.Loading = false;
          if (response.data.code == 200) {
            this.PopMessage(true, response.data.msg)
          } else {
            this.click = false;
            this.PopMessage(false, response.data.msg);
          }
        })
        .catch((error) => {
          this.Loading = false;
          // 请求失败，
          console.log(error);
        });
    },
    receive (day) {
      if (this.Loading == true) {
        return false
      }
      this.Loading = true;
      this.axios({
        // 默认请求方式为get
        method: "post",
        url: Vue.prototype.config.webplugurl + "/sign/SignReceive",
        // 传递参数
        data: {
          key: this.$route.query.key,
          keyid:this.$route.query.id,
          day: day,
        },
        responseType: "json",
      })
        .then((response) => {
          this.Loading = false;
          if (response.data.code == 200) {
            this.PopMessage(true, response.data.msg)
          } else {
            this.click = false;
            this.PopMessage(false, response.data.msg);
          }
        })
        .catch((error) => {
          this.Loading = false;
          // 请求失败，
          console.log(error);
        });
    },
    //弹出框状态
    PopMessage (type, msg) {
      this.SuccessDisplay = "display:none";
      this.MessageDisplay = "display:none";
      this.MessageMsg = msg;
      if (type == false) {
        this.MessageDisplay = "display:block";
      } else {
        this.SuccessDisplay = "display:block";
      }
    },

    //关闭窗口
    CloseAlert () {
      this.SuccessDisplay = "display:none";
      this.MessageDisplay = "display:none";
    },
    SuccessAlert () {
      this.SuccessDisplay = "display:none";
      this.MessageDisplay = "display:none";
      this.getInfo();
    },
    lj_info ($event, itmes) {
      this.itme_show_data = itmes
      var container = document.getElementsByClassName("iteminfo");
      if (typeof (container) != "undefined") {
        container[0]
        var x = $event.x
        var y = $event.y
        console.log("11")
        if (x > 1167) {
          container[0].style.left = (x - 77) + 'px'
        } else {
          container[0].style.left = (x - 73) + 'px'
        }
        container[0].style.top = y + 'px'
        container[0].style.display = 'block'
        let item_h = container[0].offsetHeight + container[0].style.top;
        if (item_h > 435) {
          y -= (item_h - 435);
          container[0].style.top = y + 'px'
        }
      }
    },
    removeActive ($event) {
      console.log($event)
      var container = document.getElementsByClassName("iteminfo");
      if (typeof (container) != "undefined") {
        container[0].style.display = 'none'
      }
    },
  },
};
</script>

<style scoped>
@import "../../../public/static/plug/sign/static/css/style.css";
</style>
<style scoped>
.el-carousel--horizontal {
  overflow-x: hidden;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel /deep/ .el-carousel__item {
  background-color: #362f27;
}
</style>

<style>
#root,
body,
html {
  min-width: 0px;
}
</style>